import {isEmpty} from 'underscore'
import TokenExchanger from './TokenExchanger'

class RedirectManager {

  async storeState(options) {
    const nonce = options.customNonce
    const currentLoc = new URL(window.location)
    currentLoc.searchParams.delete('state')

    //retrocompatibility 1.3.1 - 1.4.0
    const tokenServerUrl = options.tokenServerUrl 
      || options.oidcProvider + '/protocol/openid-connect/token'

    if(!nonce) {
      throw new Error('[RedirectManager] nonce was not specified')
    }

    let state = {
      id: nonce,
      redirectUri: currentLoc.href,
      clientId: options.clientId,
      tokenServerUrl,
      grantType: 'authorization_code',
      sessionManagement: options.sessionManagement,
      pkceCode: options.pkceCode,
      pkceMethod: options.pkceMethod,
    }

    if(options.extraInfo) {
      state.extraInfo = options.extraInfo
    }

    if(options.sessionManagement == 'token' && options.tokenStorageType) {
      state.tokenStorageType = options.tokenStorageType
    }

    console.debug(`Application state is:\n${JSON.stringify(state)}`)

    sessionStorage.setItem(nonce, JSON.stringify(state))
    return state
  }

  //for some reason keycloak.js uses # istead of ?
  async getState(fragment = true) {
    console.debug('Trying to get the state')
  
    let queryString
    if(fragment) {
      queryString = window.location.hash.replace('#','') 
    } else {
      queryString = window.location.search.replace('?','') 
    }
    const urlParams = new URLSearchParams(queryString)
    const state = urlParams.get('state')
  
    console.debug(`State ID is: ${state}`)

    if (state) {
      const stateContentStr = sessionStorage.getItem(state)
      if(stateContentStr) {
        console.debug('State content was found')
        const stateContent =  JSON.parse(stateContentStr)
        if(!stateContent.extraInfo) {
          await this.clearState(stateContent.id)
        }
        return stateContent
      } {
        console.debug('State content not found')
      }
    } else {
      console.debug('State not found')
    }
  }

  async getExtraInfo(clientId) {
    const state = await this.getState(false)
    if(state && state.clientId == clientId) {
      this.clearState(state.id)
      this.removeStateFromUrl()
      return state.extraInfo
    }

    console.debug('[RedirectManager] Extra info not found')
  }

  removeStateFromUrl() {
    const search = new URLSearchParams(window.location.search)
    search.delete('state')

    const searchString = isEmpty(search.toString()) ? '' : '?' + search.toString()
    const newUrl = window.location.protocol
      +'//'
      + window.location.host
      + window.location.pathname
      + window.location.hash
      + searchString

    window.history.replaceState({}, document.title, newUrl)
  }

  async redirect() {
    let state = await this.getState()
    return this.redirectHelper(state)
  }

  async redirectHelper(state) {
    try {
      if(!state) {
        throw new Error('State not found')
      } else {
        await new TokenExchanger().exchange(window.location, state)
        window.location = this.appendStateIfExists(state.redirectUri, state)
      }
    } catch (e) {
      const errMsg = `[Globoid-js RedirectManager.redirectHelper] ${e.message}`
      console.error(errMsg)
      // eslint-disable-next-line no-undef
      const redirectUri = encodeURIComponent(`${FLOW_ERROR_URL}?errorMsg=${errMsg}`)
      // eslint-disable-next-line no-undef
      window.location = `${FLOW_LOGOUT_URL}?url=${redirectUri}`
    }
  }

  appendStateIfExists(redirectUriStr, state) {
    const u = new URL(redirectUriStr)
    if(state.extraInfo) {
      u.searchParams.append('state', state.id)
      return u.href
    } else {
      return redirectUriStr
    }

  }

  async clearState(stateId) {
    console.debug(`Cleaning the state id: ${stateId}`)
    sessionStorage.removeItem(stateId)
  }

}

export default RedirectManager
