import SessionManager from './SessionManager'
import jwt_decode from 'jwt-decode'

class CookieSessionManager extends SessionManager {

  constructor(settings) {
    console.debug('Setting up CookieSessionManager')

    super(settings)
    this.GLOBOID_COOKIE_LIST = [
      'GLBID',
      'GLOBO_ID',
    ]
  }

  log(msg) {
    console.debug(`[cookie-session-manager] ${msg}`)
  }

  async setup() {
    await super.setup()
    this.cleanUpUrl()
  }

  cleanUpUrl() {
    console.debug('Cleaning up url')

    let splitUrl = window.location.href.split('#')

    if (splitUrl.length === 0) {
      return
    }

    let tokenList = splitUrl[1]

    if (!tokenList) {
      return
    }

    tokenList = tokenList
      .split('&')
      .filter(
        (el) => !el.startsWith('session_state')
          && !el.startsWith('code')
      )

    splitUrl[1] = tokenList.join('&')

    history.pushState({page: 1}, window.title, splitUrl.join('?'))
  }

  isLogged() {
    return this.hasNecessaryCookies(this.GLOBOID_COOKIE_LIST)
  }

  /*
 *  async logout() {
 *    if (!this.isInitialized) {
 *      await this.initialize()
 *    }
 *
 *    const globoi = window.location.hostname.includes('globoi')
 *    let removeCookie = ''
 *    if(globoi) {
 *      removeCookie = (key) => document.cookie = `${key}=; expires=${new Date(0)}; path=/; domain=.globoi.com;`
 *    //https://login.globo.com/logout
 *    } else {
 *      removeCookie = (key) => document.cookie = `${key}=; expires=${new Date(0)}; path=/; domain=.globoi.com;`
 *    }
 *
 *    this.GLOBOID_COOKIE_LIST.forEach(removeCookie)
 *    await this.keyCloakInstance.logout()
 *  }
 */

  //Testme, wrong behaviour, should check if its logged in
  loadUserInfo() {
    const getNewError = () => new Error('Could not find GLOBO_ID cookie in this session')

    const cookies = this.getCookies()

    if (!cookies) { throw getNewError() }

    if(!this.isLogged()) { throw new Error('Cannot get user info. Not logged')}

    let globoIdJWT = cookies
      .split(' ')
      .filter(cookie => cookie.startsWith('GLOBO_ID'))

    if (globoIdJWT.length !== 1) {
      throw getNewError()
    }

    globoIdJWT = globoIdJWT[0].split('=')[1]

    const decoded_jwt =  jwt_decode(globoIdJWT)
    return decoded_jwt
  }

  getTokens() {
    throw new Error('[CookieSessionManager] getTokens is not supported')
  }

}

export default CookieSessionManager
