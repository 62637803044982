import Keycloak from 'keycloak-js'
import RedirectManager from './RedirectManager'

class SessionManager {

  constructor(settings) {
    this.settings = settings

    this.isInitialized = false
    this.keyCloakInstance = null
  }

  async setup() { 
    return this.initialize()
  }

  async initialize() {
    if(this.isInitialized) {
      console.debug('SessionManager already initialized. Doing nothing')
    } else {
      this.keyCloakInstance = Keycloak(this.settings)

      try {
        console.debug(
          `Trying to init keycloak isntance with these settings:
        ${JSON.stringify(this.settings)}`
        )
        const initResult = await this.keyCloakInstance.init(this.settings)
        this.isInitialized = true
        return initResult
      } catch (e) {
        const errorMsg = `Error initializing keycloak instance. settings:
      ${JSON.stringify(this.settings)}`
        console.error(errorMsg)
        throw e
      }
    }
  }

  async login() {
    await this.initialize()
    await this.keyCloakInstance.login(this.settings.redirectUri)
  }

  async loginCustomSettings(settings) {
    if (!settings) {
      throw new Error('The required custom settings was not passed correctly')
    }

    await this.initialize()

    const {loginURL, stateId} = this.createLoginUrlCustomSettings(settings)
    await this.storeStateIfNeeded(settings, stateId)
    window.location = loginURL
  }

  async storeStateIfNeeded(options, stateId) {
    if(options.storeState) {
      console.debug('Building application state')

      return new RedirectManager().storeState({
        ...this.settings,
        ...options,
        customNonce: stateId,
        tokenServerUrl: this.keyCloakInstance.endpoints.token()
      })
    }
  }

  createLoginUrlCustomSettings(options) {
    const loginUrl = this.keyCloakInstance.createLoginUrl(
      this.settings.redirectUri
    )
    let searchParams = new URLSearchParams(loginUrl)

    const funnelMetrics = options.funnelMetrics
    if (funnelMetrics) {
      Object.keys(funnelMetrics).forEach((key) => {
        searchParams.append(key, encodeURIComponent(funnelMetrics[key]))   
      })
    }

    return {loginURL: decodeURIComponent(searchParams.toString()), stateId: searchParams.get('state')}
  }

  async logout() {
    const globoi = window.location.hostname.includes('.globoi.')
    if(globoi) {
      window.location = `https://login.qa.globoi.com/logout?url=${window.location.href}`
    } else {
      window.location = `https://login.globo.com/logout?url=${window.location.href}`
    }
  }

  loadMemoryInfo() {
    const kcInstance = this.keyCloakInstance
    return {
      id_token: kcInstance.idToken,
      refresh_token: kcInstance.refreshToken,
      access_token: kcInstance.token
    }
  }

  getCodeVerifier() {
    this.keyCloakInstance.getCodeVerifier()
  }

  getCookies() { return document.cookie }

  hasNecessaryCookies(cookieList) {
    const cookies = this.getCookies()
      .split(' ')
      .filter(cookie => cookieList.includes(cookie.split('=')[0]))

    return cookies.length === cookieList.length
  }

}

export default SessionManager
